import React from 'react';
import './Support.css';
import AppButton from '../AppButton/AppButton';
import BackButton from '../BackButton/BackButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { directionVariants } from '../../utils/directionOptions';
import { setDirection } from '../../redux/actions/actions';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { translations } from '../../utils/translations/translations';

function Support() {
  const navigate = useNavigate();
  const direction = useSelector((state) => state.direction);
  const language = useSelector((state) => state.language);
  const currentUser = useSelector((state) => state.currentUser);
  const isNoLimit = currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() === "nolimit";
  return (
    <motion.section className="support" initial={direction ? 'fromLeft' : 'fromRight'} animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }} exit={direction ? 'exitToRight' : 'exitToLeft'} variants={directionVariants}>
      <BurgerMenu color="var(--white)" />
      <BackButton text={translations[language].textTips.myVpn} path="/my-vpn" currentClass="white" title={translations[language].textTips.support} />
      <div className="empty-block"></div>
      <div className="support__text-container">
        {isNoLimit ? (
          <>
            <p className="support__text">{translations[language].support.supportNolimitText}</p>
            <p className="support__text">
              {translations[language].support.supportToFaq}
              <span onClick={() => navigate('/faq')} className="support__link">
                {translations[language].support.faq}
              </span>
            </p>
          </>
        ) : (
          <>
            <p className="support__text">{translations[language].support.supportFreeText1}</p>
            <p className="support__text">{translations[language].support.supportFreeText2}</p>
          </>
        )}
      </div>
      <div className="support__button-box">
        {!isNoLimit && (
          <>
            <AppButton
              text={translations[language].appButton.allTariffes}
              currentClass="primary blue wide"
              handler={() => {
                setDirection(true);
                navigate('/tariffes');
              }}
            />
            <AppButton
              text={translations[language].support.faq}
              currentClass="primary blue wide margin-top"
              handler={() => {
                setDirection(true);
                navigate('/faq');
              }}
            />
          </>
        )}
        <AppButton
          text={isNoLimit ? translations[language].support.callOperator : translations[language].support.supportChat}
          currentClass={`primary blue wide margin-top ${!isNoLimit ? 'support-disabled' : ''}`}
          handler={() => (window.location.href = 'https://t.me/getrobovpnBot')}
        />
      </div>
    </motion.section>
  );
}

export default Support;
