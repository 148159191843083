import React from 'react';
import './Options.css';
import BackButton from '../BackButton/BackButton';
import AppButton from '../AppButton/AppButton';
import checkboxChecked from '../../images/checkbox-checked.svg';
import checkboxNotChecked from '../../images/checkbox-not-checked.svg';
import closeButton from '../../images/close-button.png';
import checkboxDisabled from '../../images/checkbox-disabled.png';
import { useSelector } from 'react-redux';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import CopyToClipboardField from '../CopyToClipboardField/CopyToClipboardField';
import { motion } from 'framer-motion';
import { directionVariants } from '../../utils/directionOptions';
import { setDirection, setCurrentUser, setCurrentCountry } from '../../redux/actions/actions';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { setOptions } from '../../utils/roboApi';
import { translations } from '../../utils/translations/translations';

function Options() {
  const currentUser = useSelector((state) => state.currentUser);
  const currentCountry = useSelector((state) => state.currentCountry);
  const [smartActive, setIsSmartActive] = React.useState(currentUser.smart);
  const [isCommunicateActive, setIsCommunicateActive] = React.useState(currentUser.care);
  const [country, setCountry] = React.useState(currentUser.domainId);
  const direction = useSelector((state) => state.direction);
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = React.useState({
    location: false,
    device: false,
  });
  const [isSmartPopupOpen, setIsSmartPopupOpen] = React.useState(false);
  const [isBtnDisabled, SetIsBtnDisabled] = React.useState(false);
  const language = useSelector((state) => state.language);
  const [isCommunicatePopupOpen, setIsCommunicatePopupOpen] = React.useState(false);
  const [isCarePopupOpen, setIsCarePopupOpen] = React.useState(false);
  const [isOverlayActive, setIsOverlayActive] = React.useState(false);
  const isNotFree = currentUser.tariff !== 'FREE';

  function handleGenerateLink() {
    const smart = smartActive ? 1 : 0;
    SetIsBtnDisabled(true);
    setOptions(currentUser.userId, smart, isCommunicateActive, country)
      .then((res) => {
        setCurrentUser(res);
        navigate('/options/complete');
      })
      .catch((err) => console.log(err));
  }

  function handleClose() {
    setIsSmartPopupOpen(false);
    setIsCommunicatePopupOpen(false);
    setIsOverlayActive(false);
    setIsCarePopupOpen(false);
  }
  function handleCountryItemClick(e, val) {
    e.stopPropagation();
    setCountry(val);
  }
  function handleSmartPopup() {
    if (isSmartPopupOpen) {
      handleClose();
    } else {
      setIsSmartPopupOpen(true);
      setIsOverlayActive(true);
    }
  }
  function handleCarePopup() {
    if (isCarePopupOpen) {
      handleClose();
    } else {
      setIsCarePopupOpen(true);
      setIsOverlayActive(true);
    }
  }
  function handleCommunicatePopup() {
    if (isCommunicatePopupOpen) {
      handleClose();
    } else {
      setIsCommunicatePopupOpen(true);
      setIsOverlayActive(true);
    }
  }
  function handleClick(e, data) {
    e.stopPropagation();
    setIsActive(data);
  }
  function handleDisableCommunicate() {
    setIsCommunicateActive(false);
    handleClose();
  }
  React.useEffect(() => {
    if (location.pathname === '/options') {
      setCountry(currentUser.domainId);
      setIsCommunicateActive(currentUser.care);
      setIsSmartActive(currentUser.smart);
      setCurrentCountry(currentUser.domainId);
    }
  }, []);

  return (
    <motion.section className="options" initial={direction ? 'fromLeft' : 'fromRight'} animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }} exit={direction ? 'exitToRight' : 'exitToLeft'} variants={directionVariants}>
      <BurgerMenu color="var(--white)" />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <BackButton text={translations[language].textTips.myVpn} currentClass="white" path={'/my-vpn'} title="Ручная настройка" />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
                className={`options__overlay ${isOverlayActive && 'active'}`}
              />
              <div className="empty-block"></div>
              <div className="options__content">
                <div onClick={() => setIsActive({ ...isActive, location: false })} className="options__item">
                  <h2 className="options__item-title">{translations[language].options.chooseLoc}</h2>
                  {!isActive.location ? (
                    <p onClick={(e) => handleClick(e, { device: false, location: true })} className="options__item-button-text">
                      {translations[language].options.more}
                    </p>
                  ) : (
                    <div className="options__item-content">
                      <p className="options__item-text">{translations[language].options.locationText}</p>
                      <div className="options__item-location-box">
                        <span onClick={(e) => handleCountryItemClick(e, 0)} className={`options__country-item ${country === 0 && 'active'}`}>
                          Finland
                        </span>
                        <span onClick={(e) => handleCountryItemClick(e, 1)} className={`options__country-item ${country === 1 && 'active'}`}>
                          Poland
                        </span>
                        <span onClick={(e) => handleCountryItemClick(e, 2)} className={`options__country-item ${country === 2 && 'active'}`}>
                          Netherlands
                        </span>
                      </div>
                    </div>
                  )}
                  <span className={`options__country-current ${isActive.location && 'hidden'}`}>
                    {country === 0 ? 'Finland' : 'Poland'}
                    {/* Finland */}
                  </span>
                  <div
                    className="options__item-arrow-wrapper"
                    onClick={(e) => {
                      isActive.location ? setIsActive({ device: false, location: false }) : handleClick(e, { device: false, location: true });
                    }}>
                    <span className={`options__item-arrow ${isActive.location ? 'active' : ''}`} />
                  </div>
                </div>
                <div onClick={() => setIsActive({ ...isActive, device: false })} className="options__item">
                  <h2 className="options__item-title"> {translations[language].options.chooseDevice}</h2>
                  {!isActive.device ? (
                    <p onClick={(e) => handleClick(e, { location: false, device: true })} className="options__item-button-text">
                      {translations[language].options.more}
                    </p>
                  ) : (
                    <div className="options__item-content">
                      <p className="options__item-text">
                        {translations[language].options.chooseDeviceText}
                        <span
                          className="options__item-link"
                          onClick={() => {
                            setDirection(true);
                            navigate('/instruction');
                          }}>
                          {translations[language].options.chooseDeviceLink}
                        </span>
                      </p>
                      <div className="options__item-location-box">
                        <span className="options__country-item device">{translations[language].options.saveKey}</span>
                      </div>
                    </div>
                  )}
                  <div
                    className="options__item-arrow-wrapper"
                    onClick={(e) => {
                      isActive.device ? setIsActive({ device: false, location: false }) : handleClick(e, { device: true, location: false });
                    }}>
                    <span className={`options__item-arrow ${isActive.device ? 'active' : ''}`} />
                  </div>
                </div>
                {/* <div
                  className={`options__item ${
                    isSmartPopupOpen && 'with-popup'
                  }`}
                >
                  <h2 className='options__item-title'>
                    {translations[language].options.smart}
                  </h2>
                  <p
                    onClick={handleSmartPopup}
                    className='options__item-button-text'
                  >
                    {translations[language].options.more}
                  </p>
                  <div
                    onClick={() => setIsSmartActive((state) => !state)}
                    className='options__item-checkbox'
                  >
                    <img
                      src={smartActive ? checkboxChecked : checkboxNotChecked}
                      alt='checkbox'
                    />
                  </div>
                  <div
                    className={`options__popup smart ${
                      isSmartPopupOpen && 'active'
                    }`}
                  >
                    <p className='options__popup-text'>
                      {translations[language].options.smartText}
                    </p>
                    <button
                      onClick={handleClose}
                      className='options__popup-close-button'
                    >
                      <img
                        className='options__popup-close-button-image'
                        src={closeButton}
                        alt='close'
                      />
                    </button>
                  </div>
                </div> */}
                {/* <div
                  className={`options__item ${
                    isCommunicatePopupOpen && 'with-popup'
                  }`}
                >
                  <h2 className='options__item-title communicate'>
                    {translations[language].options.care}
                  </h2>
                  <p
                    onClick={handleCarePopup}
                    className='options__item-button-text'
                  >
                    {translations[language].options.more}
                  </p>
                  <div
                    className={`options__popup care ${
                      isCarePopupOpen && 'active'
                    }`}
                  >
                    <p className='options__popup-text'>
                      {translations[language].options.careText}
                    </p>
                    <button
                      onClick={handleClose}
                      className='options__popup-close-button'
                    >
                      <img
                        className='options__popup-close-button-image'
                        src={closeButton}
                        alt='close'
                      />
                    </button>
                  </div>
                  <div
                    onClick={() =>
                      isCommunicateActive
                        ? handleCommunicatePopup()
                        : setIsCommunicateActive(true)
                    }
                    className='options__item-checkbox'
                  >
                    <img
                      src={
                        isNotFree
                          ? isCommunicateActive
                            ? checkboxChecked
                            : checkboxNotChecked
                          : checkboxDisabled
                      }
                      alt='checkbox'
                    />
                  </div>
                  <div
                    className={`options__popup communicate ${
                      isCommunicatePopupOpen && 'active'
                    }`}
                  >
                    {isNotFree ? (
                      <>
                        <p className='options__popup-text'>
                          {translations[language].options.careReminder}
                        </p>
                        <div className='options__popup-button-box'>
                          <AppButton
                            text={translations[language].appButton.turnOff}
                            currentClass='secondary white small-text narrow'
                            handler={handleDisableCommunicate}
                          />
                          <AppButton
                            text={translations[language].appButton.keepOn}
                            currentClass='primary orange narrow small-text'
                            handler={handleClose}
                          />
                        </div>
                      </>
                    ) : (
                      <p className='options__popup-text'>
                        {translations[language].options.careFreeReminder}
                      </p>
                    )}
                  </div>
                </div> */}
              </div>
              <div className="options__button-box">
                {/* <span className='options__button-text'>
                  {translations[language].options.optionsButton1}
                  <br />
                  {translations[language].options.optionsButton2}
                  <br />
                  {translations[language].options.optionsButton3}
                </span> */}
                <AppButton
                  text={translations[language].appButton.saveOpt}
                  currentClass={`primary orange ${isBtnDisabled && 'disabled-opts'}`}
                  handler={() => {
                    setDirection(true);
                    handleGenerateLink();
                  }}
                />
                <AppButton text={translations[language].appButton.autoOpt} currentClass="primary orange margin-top" handler={() => navigate('/instruction')} />
              </div>
            </>
          }
        />
        <Route
          path="/complete"
          element={
            <>
              <BackButton text={translations[language].textTips.myVpn} currentClass="white" path={'/my-vpn'} />
              {/* {currentCountry === country ? (
                <div className='options__complete-content no-margin'>
                  <p className='option__complete-text-big'>
                    {translations[language].options.optSuccess}
                  </p>
                </div>
              ) : ( */}
              <div className="options__complete-content">
                <p className="option__complete-text">{translations[language].options.optSuccessKey}</p>
                <CopyToClipboardField currentClass="options__copy-to-clipboard" data={currentUser.link} gaCategory="options" />
                <p className="options__complete-tips">{translations[language].options.optionsTips}</p>
              </div>
              {/* )} */}
              <div className="options__button-box">
                <AppButton
                  text={translations[language].appButton.againOpt}
                  currentClass="primary orange"
                  handler={() => {
                    setDirection(false);
                    navigate('/options');
                  }}
                />
                <AppButton
                  text={translations[language].textTips.myVpn}
                  handler={() => {
                    setDirection(true);
                    setCurrentCountry(country);
                    navigate('/my-vpn');
                  }}
                  currentClass="primary orange margin-top"
                />
              </div>
            </>
          }
        />
      </Routes>
    </motion.section>
  );
}

export default Options;
