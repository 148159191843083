import React from 'react';
import './Subscription.css';
import BackButton from '../BackButton/BackButton';
import AppButton from '../AppButton/AppButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { directionVariants } from '../../utils/directionOptions';
import { setDirection } from '../../redux/actions/actions';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { parseTimestamp, getBonus, getIsFree, getTariffEnded, getOnlyOwn, getNextOwn } from '../../utils/helpers';
import { translations } from '../../utils/translations/translations';

function Subscription() {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);
  const language = useSelector((state) => state.language);
  const remoteData = useSelector((state) => state.remoteData);
  // 1678368993 - закончился
  // 	1679647174 - еще есть
  // const currentUser = {
  //   tariff: '10',
  //   endDate: 	1692502754 * 1000,
  //   bonusDate: 1677921307591.322,
  //   smart: 1,
  //   smartTraffic: 0,
  //   regularTraffic: 10,
  //   bonusTrafficNow: '',
  //   bonusTrafficNextMonth: '5',
  //   referralTraffic: 0,
  //   owner: 1
  // }

  const today = new Date();
  const todayUnix = Math.floor(today.getTime() / 1000);
  const isNolimit = currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' && currentUser.endActiveDate > today;
  const isFitreferral =
    (/^\d+$/.test(currentUser.tariff) && currentUser.smartTraffic <= 0 && currentUser.regularTraffic <= 0 && currentUser.referralTraffic > 0) || (/^\d+$/.test(currentUser.tariff) && currentUser.endDate < today && currentUser.referralTraffic > 0);
  const isNolimitReferral = currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' && currentUser.endActiveDate < today && currentUser.referralTraffic > 0;
  const isFree = getIsFree(currentUser);
  const isEmpty = getTariffEnded(currentUser, isFree);
  const onlyOwn = getOnlyOwn(currentUser, isEmpty, remoteData?.data?.endDate);
  const nextOwn = getNextOwn(currentUser, isEmpty, remoteData?.data?.endDate);

  const direction = useSelector((state) => state.direction);
  const ifEnded =
    (/^\d+$/.test(currentUser.tariff) && (currentUser.smartTraffic > 0 || currentUser.regularTraffic > 0) && currentUser.endDate >= today) ||
    (currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' && currentUser.endActiveDate >= today) ||
    (isFree && (currentUser.regularTraffic > 0 || currentUser.smartTraffic > 0)) ||
    onlyOwn;

  function getText() {
    switch (currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase()) {
      case 'FREE':
        return translations[language].subscription.textFree;
      case /^\d+$/.test(currentUser.tariff):
        return translations[language].subscription.textFit;
      case 'NOLIMIT':
        return translations[language].subscription.textNolimit;
      default:
        return null;
    }
  }
  return (
    <motion.section className="subscription" initial={direction ? 'fromLeft' : 'fromRight'} animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }} exit={direction ? 'exitToRight' : 'exitToLeft'} variants={directionVariants}>
      <BurgerMenu color="var(--white)" />
      <BackButton text={translations[language].backButton} path={-1} currentClass="white" title={translations[language].subscription.myTariff} />
      <div className="empty-block"></div>
      <div className="subscription__content">
        <h2 className={`subscription__subtitle ${isEmpty && !nextOwn && !onlyOwn ? 'hidden' : ''}`}>{translations[language].subscription.yourTariff}</h2>
        <h1 className="subscription__title">
          {isEmpty && !nextOwn && !onlyOwn
            ? translations[language].subscription.ended
            : !onlyOwn && /^\d+$/.test(currentUser.tariff)
            ? 'FIT ' + (currentUser.tariff + translations[language].textTips.gb)
            : onlyOwn
            ? 'OWN'
            : currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toUpperCase()}
        </h1>

        {/* {(((/^\d+$/).test(currentUser.tariff) 
              && (currentUser.smartTraffic > 0 || currentUser.regularTraffic > 0) && (currentUser.endDate >= today))
              || (currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' && (currentUser.endActiveDate >= today))
              || (isFree && (currentUser.regularTraffic > 0 || currentUser.smartTraffic > 0)))
              || onlyOwn */}
        {/* && ( */}
        <div className={`subscription__smart ${currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' ? 'nolimit' : 'fit'} ${currentUser.smart === 1 && ifEnded ? '' : 'hidden'}`}>{translations[language].tariffes.smart}</div>
        <div className="subscription__sub">
          {(isEmpty && !nextOwn && !onlyOwn) || isNolimitReferral
            ? ''
            : currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit'
            ? translations[language].subscription.textNolimit
            : /^\d+$/.test(currentUser.tariff)
            ? translations[language].subscription.textFitSub
            : translations[language].subscription.textFree}
        </div>

        <p className="subscription__text">{getText()}</p>

        {/* <div className={`subscription__subtitle_ended ${isEmpty && !nextOwn && !onlyOwn ? '' : 'hidden'}`}>{translations[language].subscription.toGet + parseTimestamp(currentUser.bonusDate)}</div> */}

        <div className={`${currentUser.tariff === '5' ? 'subscription__widget_flex' : ''} ${(isEmpty && !nextOwn && !onlyOwn) || isNolimitReferral || isFitreferral ? 'subscription__widgets_hidden' : ''}`}>
          <div className={`subscription__widget-box ${currentUser.tariff === '5' && 'nogb'} ${(isNolimit || onlyOwn) && 'centered'}`}>
            <div className={`subscription__widget ${currentUser.tariff === '5' && 'nogb'}`}>
              <span className="subscription__widget-text">{translations[language].textTips.activeUntil}</span>
              <span className={`subscription__widget-value`}>{onlyOwn ? parseTimestamp(remoteData?.data?.endDate) : isNolimit ? parseTimestamp(currentUser.endActiveDate) : parseTimestamp(currentUser.endDate)}</span>
            </div>

            {currentUser.tariff === '5' ? null : (
              <div className={`subscription__widget ${(isNolimit || onlyOwn) && 'subscription__widget_disabled'}`}>
                {currentUser.bonusDate < today && !onlyOwn ? (
                  <span className="subscription__widget-text">
                    {translations[language].subscription.nextMonth}
                    {3}
                    {translations[language].textTips.gb}
                    {translations[language].subscription.alreadyCounted}
                  </span>
                ) : (
                  <span className="subscription__widget-text">
                    {translations[language].subscription.algoRobo}
                    <b>
                      {!currentUser.trafficMonth && translations[language].textTips.not}
                      {translations[language].textTips.enough}
                    </b>{' '}
                    {translations[language].subscription.algoText + '3' + translations[language].textTips.gb}
                  </span>
                )}
              </div>
            )}
          </div>

          <div className={`subscription__widget_secondary ${currentUser.tariff === '5' && 'noalgo'} ${(isNolimit || onlyOwn) && 'hidden'}`}>
            {
              // (getBonus(currentUser.bonusTrafficNow) === 0 || getBonus(currentUser.bonusTrafficNextMonth) > 0)
              //   ?
              isFree ? (
                <span className="subscription__widget-text_secondary">
                  {translations[language].subscription.nextMonth}
                  <b>{'3' + translations[language].textTips.gb}</b>
                  {translations[language].subscription.willCount}
                  {translations[language].subscription.added}
                  {parseTimestamp(currentUser.bonusDate)}
                  <br />
                  {translations[language].subscription.bonusRestText}
                  <b>{/^\d+$/.test(currentUser.tariff) ? 'FIT' : currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toUpperCase()}</b>
                </span>
              ) : (
                <span className="subscription__widget-text_secondary">
                  {translations[language].subscription.nextMonth}
                  <b>{'3' + translations[language].textTips.gb}</b>
                  {translations[language].subscription.willCount}
                  {translations[language].subscription.added}
                  {parseTimestamp(currentUser.bonusDate)}
                  <br />
                  {translations[language].subscription.restText}
                </span>
              )
              // :  (
              //   <span className='subscription__widget-text_secondary'>
              //     {translations[language].subscription.toGet}
              //     {currentUser.bonusDate > 0 ? parseTimestamp(currentUser.bonusDate) : parseTimestamp(currentUser.endDate)}
              //   </span>
              //   )
            }
          </div>

          <div className={`subscription__widget_secondary ${!nextOwn && 'hidden'}`}>
            <span className="subscription__widget-text">{translations[language].subscription.nextOwn}</span>
          </div>
        </div>
      </div>
      <div className="subscription__button-box">
        {isNolimit ? (
          <>
            <AppButton
              text={translations[language].appButton.continueTariff}
              handler={() => {
                setDirection(true);
                navigate('/tariffes/nolimit');
              }}
              currentClass="primary dark-blue margin-bottom wide"
            />
            <AppButton
              text={translations[language].appButton.giftRobo}
              handler={() => {
                setDirection(true);
                navigate('/gift');
              }}
              currentClass="secondary white wide"
            />
          </>
        ) : (
          <>
            <AppButton
              text={translations[language].appButton.payGb}
              handler={() => {
                setDirection(true);
                currentUser.activeUser ? navigate('/tariffes/fit') : navigate('/tariffes');
              }}
              currentClass="primary dark-blue margin-bottom wide"
            />
            <AppButton
              text={translations[language].appButton.myTraffic}
              handler={() => {
                setDirection(true);
                navigate('/traffic');
              }}
              currentClass="secondary white wide"
            />
          </>
        )}
      </div>
    </motion.section>
  );
}

export default Subscription;
