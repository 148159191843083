import React from "react";
import "./MyVpn.css";
import MenuButton from "../MenuButton/MenuButton";
import BackButton from "../BackButton/BackButton";
import balanceIcon from "../../images/balance.png";
import trafficIcon from "../../images/traffic.png";

import supportIcon from "../../images/support.png";
import happySmile from "../../images/values.png";
import optionsIcon from "../../images/options-icon.svg";
import remoteIcon from "../../images/remote_icon.svg";
import letterIcon from "../../images/letter-min.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { directionVariants } from "../../utils/directionOptions";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { parseTimestamp, getBonus, roundTraffic, getRefTraffic, getIsFree, getTariffEnded, getOnlyOwn } from "../../utils/helpers";
import { translations } from "../../utils/translations/translations";

function MyVpn() {
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.currentUser);
    const remoteData = useSelector((state) => state.remoteData);
    // 1678368993 - закончился
    // 1679077973
    // const currentUser = {
    //   tariff: '20',
    //   endDate: 1678368993 * 1000,
    //   bonusDate: 1677921307591.322,
    //   smart: 0,
    //   smartTraffic: 0,
    //   regularTraffic: 10,
    //   bonusTrafficNow: '',
    //   bonusTrafficNextMonth: '5',
    //   referralTraffic: 0,
    //   daysLeft: 5
    // }
    const today = new Date();
    const isFree = getIsFree(currentUser, getBonus);
    const language = useSelector((state) => state.language);
    const tariffEnded = getTariffEnded(currentUser, getBonus, isFree);
    const onlyOwn = getOnlyOwn(currentUser, tariffEnded, remoteData?.data?.endDate);
    const nextFit = !isNaN(Number(currentUser.firstFitTariff)) && currentUser.firstFitTariff.trim() !== "" && typeof currentUser.firstFitTariff !== "string" ? currentUser.firstFitTariff : 0;

    const direction = useSelector((state) => state.direction);

    return (
        <motion.section
            className="my-vpn"
            initial={direction ? "fromLeft" : "fromRight"}
            animate={{
                x: 0,
                opacity: 1,
                transition: { duration: 0.2, delay: 0.2 },
            }}
            exit={direction ? "exitToRight" : "exitToLeft"}
            variants={directionVariants}
        >
            <BurgerMenu color="var(--blue)" />
            <BackButton path="/" text={translations[language].appButton.mainMenu} currentClass="" title={translations[language].textTips.myVpn} />
            <div className="empty-block"></div>
            <div className="my-vpn__button-container">
                <MenuButton
                    handler={() => navigate("/gift")}
                    image={letterIcon}
                    currentClass="btn-balance"
                    title={translations[language].textTips.gift}
                    text={translations[language].textTips.giftBtnText}
                    addText={null}
                />
                <MenuButton
                    image={trafficIcon}
                    handler={() => navigate("/traffic")}
                    currentClass="btn-traffic"
                    title={translations[language].textTips.traffic}
                    text={
                        !onlyOwn
                            ? translations[language].textTips.trafficText
                            : translations[language].textTips.tariff +
                              (!onlyOwn && /^\d+$/.test(currentUser.tariff) ? translations[language].tariffes.smart : onlyOwn ? "OWN" : currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toUpperCase()) +
                              (currentUser.referralTraffic + currentUser.smartTraffic >= currentUser.regularTraffic ? translations[language].tariffes.smart : "") +
                              (tariffEnded && !onlyOwn ? translations[language].tariffes.tariffEnded : "")
                    }
                    addText={!onlyOwn ? "" : translations[language].textTips.activeUntil + (onlyOwn ? parseTimestamp(remoteData?.data?.endDate) : parseTimestamp(currentUser.endActiveDate))}
                />
                <MenuButton
                    image={optionsIcon}
                    handler={() => navigate("/options")}
                    currentClass="btn-options"
                    title={translations[language].textTips.options}
                    text={translations[language].textTips.optionsText}
                    addText={null}
                />
                <MenuButton
                    image={supportIcon}
                    handler={() => navigate("/support")}
                    currentClass="btn-support"
                    title={translations[language].textTips.support}
                    text={translations[language].textTips.supportText}
                    addText={null}
                />
            </div>
            <MenuButton
                image={happySmile}
                currentClass="btn-my-tariff"
                title={translations[language].subscription.myTariff}
                text={
                    translations[language].textTips.tariff +
                    (!onlyOwn && /^\d+$/.test(currentUser.tariff) ? "FIT" : onlyOwn ? "OWN" : currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toUpperCase()) +
                    (currentUser.referralTraffic + currentUser.smartTraffic >= currentUser.regularTraffic ? translations[language].tariffes.smart : "") +
                    (tariffEnded && currentUser.referralTraffic <= 0 ? translations[language].tariffes.tariffEnded : "")
                }
                addText={
                    isFree && (currentUser.regularTraffic > 0 || currentUser.smartTraffic > 0 || currentUser.referralTraffic > 0)
                        ? translations[language].textTips.left + getRefTraffic(currentUser.referralTraffic) + translations[language].textTips.gb
                        : !onlyOwn &&
                          ((/^\d+$/.test(currentUser.tariff) &&
                              currentUser.smartTraffic <= 0 &&
                              currentUser.referralTraffic <= 0 &&
                              currentUser.regularTraffic <= 0 &&
                              getBonus(currentUser.bonusTrafficNow) === 0) ||
                              (/^\d+$/.test(currentUser.tariff) && currentUser.endDate < today && currentUser.referralTraffic < 0) ||
                              (isFree && currentUser.regularTraffic <= 0 && currentUser.smartTraffic <= 0 && currentUser.referralTraffic <= 0) ||
                              (currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() === "nolimit" && currentUser.endActiveDate < today && currentUser.referralTraffic <= 0))
                        ? translations[language].textTips.freeGb + parseTimestamp(currentUser.bonusDate)
                        : (currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() === "nolimit" && currentUser.endActiveDate > today) || onlyOwn
                        ? translations[language].textTips.activeUntil + (onlyOwn ? parseTimestamp(remoteData?.data?.endDate) : parseTimestamp(currentUser.endActiveDate))
                        : translations[language].textTips.left +
                          (currentUser.smartTraffic + currentUser.regularTraffic + parseInt(getRefTraffic(currentUser.referralTraffic)) + nextFit).toFixed(1) +
                          translations[language].textTips.gb +
                          (isFree ? "" : translations[language].textTips.till + parseTimestamp(currentUser.endDate))
                }
                handler={() => navigate("/subscription")}
            />
        </motion.section>
    );
}

export default MyVpn;
