import React from 'react';
import './Gift.css';
import Popup from '../Popup/Popup';
import AppButton from '../AppButton/AppButton';
import { useSwipeable } from 'react-swipeable';
import { useSelector } from 'react-redux';
import FormLabel from '../FormLabel/FormLabel';
import { useNavigate } from 'react-router-dom';
import { setDirection, setNextTariff, setPayment, setPaymentUrl } from '../../redux/actions/actions';
import { motion } from 'framer-motion';
import { directionVariants } from '../../utils/directionOptions';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { translations } from '../../utils/translations/translations';
import { getTotalDiscountedValue } from '../../utils/helpers';
import SmartToggle from '../SmartToggle/SmartToggle';
import { setPaymentSmart } from '../../redux/actions/actions';
import TariffesTemplatePopup from '../TariffesTemplatePopup/TariffesTemplatePopup';
import video from '../../resources/video.MP4';

const variants = {
  visible: { opacity: 1, transition: { duration: 0.2 } },
  faded: { opacity: 0, transition: { duration: 0.2 } },
};

function Gift() {
  const [progress, setProgress] = React.useState(0);
  const [value, setValue] = React.useState('0');
  const [isFaded, setIsFaded] = React.useState(false);
  const [isGiftPopupHidden, setIsGiftPopupHidden] = React.useState(true);
  const [isExampleHidden, setIsExampleHidden] = React.useState(true);
  const currentUser = useSelector((state) => state.currentUser);
  const direction = useSelector((state) => state.direction);
  const language = useSelector((state) => state.language);
  const prices = useSelector((state) => state.prices);
  const navigate = useNavigate();
  const isGiftDiscounted = currentUser.giftDiscount > 0;
  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });
  const [smart, setSmart] = React.useState(false);
  const [active, setActive] = React.useState();
  // const [src, setSrc] = React.useState("");

  // const handleVideo = (event) => {
  //   try {
  //     // Get the uploaded file
  //     const file = event.target.files[0];
  //     console.log(file)
  //     // Transform file into blob URL
  //     setSrc(URL.createObjectURL(file));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleChecked = () => {
    setSmart((curr) => !curr);
  };

  React.useEffect(() => {
    if (smart) {
      setPaymentSmart('smart');
    } else {
      setPaymentSmart('regular');
    }
  }, [smart]);

  React.useEffect(() => {
    isFaded && setTimeout(setIsFaded, 300, false);
  }, [isFaded]);

  function handleSwipeLeft() {
    if (progress >= 2) {
      setProgress(2);
    } else {
      setIsFaded(true);
      setTimeout(setProgress, 300, (state) => ++state);
    }
  }
  function handleSwipeRight() {
    if (progress <= 0) {
      setProgress(0);
    } else {
      setSmart(false);
      setIsFaded(true);
      setTimeout(setProgress, 300, (state) => --state);
    }
  }

  function handleClick() {
    setIsFaded(true);
    setTimeout(setProgress, 300, (state) => ++state);
  }
  function handleSubmit(e) {
    e.preventDefault();
    handleClick();
  }
  function handlePaymentSubmit() {
    setPaymentUrl('gift-success');
    setDirection(true);
    if (value === '2') {
      isGiftDiscounted ? setPayment(getTotalDiscountedValue(prices.Nolimit_12, 12, currentUser.giftDiscount)) : setPayment(smart ? prices.Nolimit_12[1] * 12 : prices.Nolimit_12[1] * 12);
      setNextTariff('Nolimit_12');
    } else if (value === '1') {
      isGiftDiscounted ? setPayment(getTotalDiscountedValue(prices.Nolimit_3, 3, currentUser.giftDiscount)) : setPayment(smart ? prices.Nolimit_3[1] * 3 : prices.Nolimit_3[0] * 3);
      setNextTariff('Nolimit_3');
    } else if (value === '3') {
      isGiftDiscounted ? setPayment(getTotalDiscountedValue(prices.Nolimit_24, 24, currentUser.giftDiscount)) : setPayment(smart ? prices.Nolimit_24[1] * 24 : prices.Nolimit_24[0] * 24);
      setNextTariff('Nolimit_3');
    } else {
      isGiftDiscounted ? setPayment(getTotalDiscountedValue(prices.Nolimit_1, 1, currentUser.giftDiscount)) : setPayment(smart ? prices.Nolimit_1[1] : prices.Nolimit_1[0]);
      setNextTariff('Nolimit_1');
    }
    setIsFaded(true);
    navigate('/payment');
  }
  function handleBackButtonClick() {
    if (progress > 0) {
      setIsFaded(true);
      setTimeout(setProgress, 300, (state) => --state);
      setValue('0');
      setSmart(false);
    } else {
      setDirection(false);
      navigate('/');
    }
  }
  return (
    <>
      <motion.section
        {...handlers}
        className="gift"
        initial={direction ? 'fromLeft' : 'fromRight'}
        animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
        exit={direction ? 'exitToRight' : 'exitToLeft'}
        variants={directionVariants}>
        <BurgerMenu color="var(--white)" />
        <button onClick={handleBackButtonClick} className="gift__back-button">
          {progress > 0 ? <span className="gift__back-button-text">{translations[language].appButton.back}</span> : <span className="gift__back-button-text">{translations[language].appButton.mainMenu}</span>}
          <span className="gift__back-button-corner" />
          {progress === 0 ? <span className="gift__back-button-title">{translations[language].gift.giftVpn}</span> : null}
        </button>
        <div className="empty-block"></div>
        <motion.div className="gift__content" initial={{ opacity: 0 }} animate={isFaded ? 'faded' : 'visible'} variants={variants}>
          {progress === 0 && (
            <>
              <h1 className="gift__title">
                {translations[language].gift.giftP1Title1}
                <br />
                {translations[language].gift.giftP1Title2}
              </h1>
              <h2 className="gift__subtitle">
                {translations[language].gift.giftP1Subtitle1}
                <br /> {translations[language].gift.giftP1Subtitle2}
                <br /> {translations[language].gift.giftP1Subtitle3}
                <br /> {translations[language].gift.giftP1Subtitle4}
              </h2>
              <AppButton text={translations[language].appButton.seeGift} currentClass="primary orange" handler={() => setIsExampleHidden(false)} />
              <AppButton currentClass="primary orange margin-top" text={translations[language].appButton.selectGift} handler={handleClick} />
              <span className="gift__explanation">{translations[language].gift.giftExplanation}</span>
              <Popup title={translations[language].textTips.termsAndConditions} currentClass="popup-gift" isHidden={isGiftPopupHidden} handleHide={setIsGiftPopupHidden}>
                {
                  <div className="popup-gift__text">
                    <p className="gift__popup-text">{translations[language].gift.giftPopupText1}</p>
                    <p className="gift__popup-text"> {translations[language].gift.giftPopupText2}</p>
                    <p className="gift__popup-text">{translations[language].gift.giftPopupText3}</p>
                    <p className="gift__popup-text">{translations[language].gift.giftPopupText4}</p>
                    <AppButton text={translations[language].appButton.gotIt} currentClass="secondary white bg-orange border-transparent margin-top" handler={() => setIsGiftPopupHidden(true)} />
                  </div>
                }
              </Popup>
            </>
          )}
          {progress === 1 && (
            <>
              <h1 className="gift__title">
                {translations[language].gift.giftP2Title1}
                <br />
                {translations[language].gift.giftP2Title2}
              </h1>
              <form onSubmit={handleSubmit} id="tariff-form" className="gift__form">
                <FormLabel
                  elementValue="0"
                  name="gift"
                  handler={(data) => {
                    setValue(data);
                    setActive('0');
                  }}
                  currentClass={`form-label-item-gift  ${isGiftDiscounted && 'form-label-item-gift_discounted'} ${smart ? 'colored' : ''}`}
                  title={translations[language].tariffes.nolimitMonth}
                  text={null}
                  valueMain={
                    // isGiftDiscounted
                    //   ? prices.Nolimit_1
                    //   : prices.Nolimit_1 + translations[language].textTips.currency
                    (smart ? prices.Nolimit_1[1] : prices.Nolimit_1[0]) + translations[language].textTips.currencyMonth
                  }
                  valueSecondary={isGiftDiscounted ? getTotalDiscountedValue(prices.Nolimit_1, 1, currentUser.giftDiscount) + translations[language].textTips.currency : prices.Nolimit_1 + translations[language].textTips.currency}
                  isDiscounted={isGiftDiscounted}
                  currentUserDiscount={currentUser.giftDiscount}
                  defaultChecked={true}
                />
                <FormLabel
                  elementValue="1"
                  name="gift"
                  handler={(data) => {
                    setValue(data);
                    setActive('1');
                  }}
                  currentClass={`form-label-item-gift  ${isGiftDiscounted && 'form-label-item-gift_discounted'} ${smart ? 'colored' : ''}`}
                  title={translations[language].tariffes.nolimit3Months}
                  text={null}
                  valueMain={
                    // isGiftDiscounted
                    //   ? prices.Nolimit_3
                    //   : prices.Nolimit_3 + translations[language].textTips.currencyMonth
                    (smart ? prices.Nolimit_3[1] : prices.Nolimit_3[0]) + translations[language].textTips.currencyMonth
                  }
                  valueSecondary={isGiftDiscounted ? getTotalDiscountedValue(prices.Nolimit_3, 1, currentUser.giftDiscount) + translations[language].textTips.currencyMonth : prices.Nolimit_3 + translations[language].textTips.currencyMonth}
                  isDiscounted={false}
                  isProfit={currentUser.discount || active === '1'}
                  currentUserDiscount={currentUser.giftDiscount}
                  isRecommended={active === '1' ? true : false}
                  profit={currentUser.discount ? currentUser.discount : smart ? Math.round((1 - (prices.Nolimit_3[1] * 3) / (prices.Nolimit_1[1] * 3)) * 100) : Math.round((1 - (prices.Nolimit_3[0] * 3) / (prices.Nolimit_1[0] * 3)) * 100)}
                />
                <FormLabel
                  elementValue="2"
                  name="gift"
                  handler={(data) => {
                    setValue(data);
                    setActive('2');
                  }}
                  currentClass={`form-label-item-gift ${isGiftDiscounted && 'form-label-item-gift_discounted'} ${smart ? 'colored' : ''}`}
                  title={translations[language].tariffes.nolimit12Months}
                  text={null}
                  valueMain={
                    // isGiftDiscounted
                    //   ? prices.Nolimit_12
                    //   : prices.Nolimit_12 + translations[language].textTips.currencyMonth
                    (smart ? prices.Nolimit_12[1] : prices.Nolimit_12[0]) + translations[language].textTips.currencyMonth
                  }
                  valueSecondary={isGiftDiscounted ? getTotalDiscountedValue(prices.Nolimit_12, 1, currentUser.giftDiscount) + translations[language].textTips.currencyMonth : prices.Nolimit_12 + translations[language].textTips.currencyMonth}
                  isDiscounted={false}
                  isProfit={currentUser.discount || active === '2'}
                  currentUserDiscount={currentUser.giftDiscount}
                  isRecommended={active === '2' ? true : false}
                  profit={currentUser.discount ? currentUser.discount : smart ? Math.round((1 - (prices.Nolimit_12[1] * 12) / (prices.Nolimit_1[1] * 12)) * 100) : Math.round((1 - (prices.Nolimit_12[0] * 12) / (prices.Nolimit_1[0] * 12)) * 100)}
                />
                <FormLabel
                  elementValue="3"
                  name="gift"
                  handler={(data) => {
                    setValue(data);
                    setActive('3');
                  }}
                  currentClass={`form-label-item-gift ${isGiftDiscounted && 'form-label-item-gift_discounted'} ${smart ? 'colored' : ''}`}
                  title={translations[language].tariffes.nolimit24Months}
                  text={null}
                  valueMain={
                    // isGiftDiscounted
                    //   ? prices.Nolimit_12
                    //   : prices.Nolimit_12 + translations[language].textTips.currencyMonth
                    (smart ? prices.Nolimit_24[1] : prices.Nolimit_24[0]) + translations[language].textTips.currencyMonth
                  }
                  valueSecondary={isGiftDiscounted ? getTotalDiscountedValue(prices.Nolimit_24, 1, currentUser.giftDiscount) + translations[language].textTips.currencyMonth : prices.Nolimit_24 + translations[language].textTips.currencyMonth}
                  isDiscounted={false}
                  isProfit={currentUser.discount || active === '3'}
                  currentUserDiscount={currentUser.giftDiscount}
                  isRecommended={active === '3' ? true : false}
                  profit={currentUser.discount ? currentUser.discount : smart ? Math.round((1 - (prices.Nolimit_24[1] * 24) / (prices.Nolimit_1[1] * 24)) * 100) : Math.round((1 - (prices.Nolimit_24[0] * 24) / (prices.Nolimit_1[0] * 24)) * 100)}
                />
              </form>
              <SmartToggle currentClass={'gift-color'} gift={'gift'} onChange={handleChecked} />

              <motion.button
                whileHover={{ scale: 0.95, transition: { duration: 0.2 } }}
                whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
                exit={{ opacity: 0, transition: { duration: 0.1 } }}
                className="gift__submit-button  margin-top"
                type="submit"
                form="tariff-form"
                value="submit">
                {translations[language].appButton.next}
              </motion.button>
            </>
          )}
          {progress === 2 && (
            <>
              <h1 className="gift__title">
                {translations[language].gift.giftP3Title1}
                <br />
                {translations[language].gift.giftP3Title2}
              </h1>
              <h2 className="gift__subtitle">
                {translations[language].gift.giftP3Text1}
                <br />
                {translations[language].gift.giftP3Text2}
                <br />
                {translations[language].gift.giftP3Text3}
                <br />
                {translations[language].gift.giftP3Text4}
                <br />
                {translations[language].gift.giftP3Text5}
              </h2>
              <span className={`gift__user-selection ${smart ? 'colored' : ''}`}>
                {value === '0' && (
                  <span className="gift__user-selection-placeholder">
                    {translations[language].tariffes.nolimitMonth}
                    {smart && translations[language].tariffes.plus}
                    <br />
                    {smart ? translations[language].tariffes.plusSmart : null}
                    <div className="gift__user-selection-placeholder-value">
                      <span>
                        {isGiftDiscounted
                          ? getTotalDiscountedValue(prices.Nolimit_1, 1, currentUser.giftDiscount) + translations[language].textTips.currency
                          : (smart ? prices.Nolimit_1[1] : prices.Nolimit_1[0]) + translations[language].textTips.currency}
                      </span>
                    </div>
                  </span>
                )}
                {value === '1' && (
                  <span className="gift__user-selection-placeholder">
                    {translations[language].tariffes.nolimit3Months}
                    {smart && translations[language].tariffes.plus}
                    <br />
                    {smart ? translations[language].tariffes.plusSmart : null}
                    <div className="gift__user-selection-placeholder-value">
                      <span>
                        {/* {isGiftDiscounted
                        ? getTotalDiscountedValue(
                            prices.Nolimit_3,
                            3,
                            currentUser.giftDiscount
                          ) + translations[language].textTips.currency
                        : prices.Nolimit_3 * 3 +
                          translations[language].textTips.currency
                          } */}
                        {(smart ? prices.Nolimit_3[1] * 3 : prices.Nolimit_3[0] * 3) + translations[language].textTips.currency}
                      </span>
                    </div>
                  </span>
                )}
                {value === '2' && (
                  <span className="gift__user-selection-placeholder">
                    {translations[language].tariffes.nolimit12Months}
                    {smart && translations[language].tariffes.plus}
                    <br />
                    {smart ? translations[language].tariffes.plusSmart : null}
                    <div className="gift__user-selection-placeholder-value">
                      <span>
                        {/* {isGiftDiscounted
                        ? getTotalDiscountedValue(
                            prices.Nolimit_12,
                            12,
                            currentUser.giftDiscount
                          ) + translations[language].textTips.currency
                        : prices.Nolimit_12 * 12 +
                          translations[language].textTips.currency} */}
                        {(smart ? prices.Nolimit_12[1] * 12 : prices.Nolimit_12[0] * 12) + translations[language].textTips.currency}
                      </span>
                    </div>
                  </span>
                )}
              </span>
              <AppButton currentClass="primary orange" text={translations[language].appButton.goToPayment} handler={handlePaymentSubmit} />
            </>
          )}
        </motion.div>
      </motion.section>

      <TariffesTemplatePopup isHidden={isExampleHidden} setIsHidden={setIsExampleHidden} currentClass={'gift-example'}>
        <video width={'100%'} height={'100%'} controls>
          <source src={video} type="video/mp4" />
          Ваше устройство не поддерживает проигрывание видео
        </video>
        {/* <iframe
      width={'100%'}
      height={'100%'}
      className='tariffes-template__iframe'
      src={`https://youtube.com/embed/-rlT4wIV6aY?controls=0&showinfo=0&loop=1&rel=0&modestbranding=1&fs=0`}
      title="Youtube Player"
      frameborder='0'
    /> */}
      </TariffesTemplatePopup>
    </>
  );
}

export default Gift;
