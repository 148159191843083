import React from "react";
import "./TariffesTemplate.css";
import BackButton from "../BackButton/BackButton";
import { motion } from "framer-motion";
import AppButton from "../AppButton/AppButton";
import { useNavigate } from "react-router-dom";
import CurrentTariffWidget from "../CurrentTariffWidget/CurrentTariffWidget";
import PropTypes from "prop-types";
import { translations } from "../../utils/translations/translations";
import SmartToggle from "../SmartToggle/SmartToggle";
import { setPaymentSmart } from "../../redux/actions/actions";
import { useSelector } from "react-redux";
import { parseTimestamp } from "../../utils/helpers";

const toggleVariants = {
    notest: {
        x: "0%",
    },
    test: {
        x: "100%",
    },
};

function TariffesTemplate({
    currentClass,
    buttonText,
    handler,
    children,
    error,
    setIsFreeInfoHidden,
    setIsHidden,
    setIsRecommendHidden,
    setIsGbHidden,
    onChangeCheck,
    onChangeTestCheck,
    setIsMoreHidden,
    isPaymentHidden,
    handleProgress,
    onSetIsWithRoboBalance,
    paymentSmart,
    isSpeedHidden,
    ownProgress,
    ownPersonCount,
    setOwnProgress,
    backButtonOwn,
    nolimitResPrice,
    nolimitFullPrice,
    fitResPrice,
    ownFullPrice,
    ownResPrice,
    fitFullPrice,
}) {
    const navigate = useNavigate();
    const today = new Date();
    const currentUser = useSelector((state) => state.currentUser);
    const remoteData = useSelector((state) => state.remoteData);
    const language = useSelector((state) => state.language);
    const [smart, setSmart] = React.useState(false);
    const isTested = 0;
    const [withRoboBalance, setWithRoboBalance] = React.useState(false);
    const isNeverOwn = currentUser.owner === 0 && currentUser.own_tested === 0;
    const isTestOwnTariff = currentUser.own_tested === 1 && (remoteData?.data?.tariff === "ownme_test" || remoteData?.data?.tariff === "ownfam_test" || remoteData?.data?.tariff === "owncom_test");
    const isOwnTariff = currentUser.owner === 1 && remoteData?.data?.tariff !== "ownme_test" && remoteData?.data?.tariff !== "ownfam_test" && remoteData?.data?.tariff !== "owncom_test";
    const tariffType = remoteData?.data?.tariff?.replace(/[^a-zA-Z]/g, "");
    const [isTestChecked, setIsTestchecked] = React.useState(false);

    const getOwnTariffTypeGb = () => {
        if (tariffType === "ownme" || tariffType === "ownme__test") {
            return 500;
        }
        if (tariffType === "ownfam" || tariffType === "ownfam__test") {
            return 1000;
        }

        if (tariffType === "owncom" || tariffType === "owncom__test") {
            return 2000;
        }
    };

    const switchIsRoboBalance = () => {
        setWithRoboBalance(!withRoboBalance);
        onSetIsWithRoboBalance(!withRoboBalance);
    };

    const handleTestCheck = () => {
        setIsTestchecked((curr) => !curr);
        onChangeTestCheck();
    };

    const getTestedDays = () => {
        const endDate = new Date(remoteData?.data?.endDate);
        const diffInMs = Math.abs(today - endDate);
        const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
        return diffInDays;
    };

    const handleChecked = () => {
        setSmart((curr) => !curr);
        onChangeCheck(smart);
    };

    React.useEffect(() => {
        if (smart) {
            setPaymentSmart("smart");
        } else {
            setPaymentSmart("regular");
        }
    }, [smart]);

    return (
        <section className={`tariffes-template ${currentClass} ${currentClass === "own" && ownProgress === 0 && "own-persons"} ${currentClass === "own" && ownProgress === 2 && "own-country"}`}>
            <BackButton
                text={translations[language].backButton}
                title={translations[language].textTips.tariff + currentClass.toUpperCase()}
                path={-1}
                currentClass={`back-button-tariffes-template ${ownProgress === 0 && "own-first"}`}
                own={backButtonOwn ? true : false}
                setOwnProgress={setOwnProgress}
                ownProgress={ownProgress}
            />
            {currentClass === "own" ? (
                ownProgress === 1 ? (
                    <div className={`tariffes-template__speed`}>
                        {translations[language].tariffOwn.ownPersons1}
                        {ownPersonCount === "me" ? "1-5" : ownPersonCount === "fam" ? "6-10" : "11-20"}
                        {translations[language].tariffOwn.ownPersons2}
                        <br />
                        {ownPersonCount === "me" ? "500" : ownPersonCount === "fam" ? "1000" : "2000"}
                        {translations[language].tariffOwn.ownPersons3}
                    </div>
                ) : ownProgress === 0 ? (
                    isNeverOwn ? (
                        <div className="tariffes-template__speed">
                            {translations[language].tariffOwn.ownSpeed1}
                            <br />
                            {translations[language].tariffOwn.ownSpeed2}
                        </div>
                    ) : isOwnTariff ? (
                        <div className="tariffes-template__speed">
                            {translations[language].tariffOwn.ownWidget1}
                            {","}
                            {remoteData?.data?.endDate > today ? translations[language].tariffOwn.ownWidget5 + parseTimestamp(remoteData?.data?.endDate) : null}
                            <br />
                            {remoteData?.data?.endDate > today
                                ? remoteData?.data?.trafficTotal > 0
                                    ? translations[language].tariffOwn.ownWidget7 +
                                      (remoteData?.data?.nextGB ? parseTimestamp(remoteData?.data?.nextGB) : parseTimestamp(remoteData?.data?.endDate)) +
                                      translations[language].tariffOwn.ownWidget8 +
                                      remoteData?.data?.trafficTotal +
                                      translations[language].textTips.gb
                                    : translations[language].tariffOwn.ownWidget9 +
                                      getOwnTariffTypeGb() +
                                      translations[language].textTips.gb +
                                      " " +
                                      (remoteData?.data?.nextGB ? parseTimestamp(remoteData?.data?.nextGB) : parseTimestamp(remoteData?.data?.endDate))
                                : null}
                        </div>
                    ) : isTestOwnTariff && remoteData?.data?.endDate >= today ? (
                        <div className="tariffes-template__speed">
                            {translations[language].tariffOwn.ownWidget1}
                            <br />
                            {translations[language].tariffOwn.ownWidget2}
                            {getTestedDays()}
                            {translations[language].tariffOwn.ownWidget10}
                        </div>
                    ) : (
                        <div className="tariffes-template__speed">
                            {translations[language].tariffOwn.ownWidget1}
                            <br />
                            {translations[language].tariffOwn.ownWidget4}
                        </div>
                    )
                ) : null
            ) : currentUser.owner === 1 ? (
                isSpeedHidden ? null : ownProgress === 1 ? (
                    <div className="tariffes-template__speed">
                        {translations[language].tariffOwn.ownPersons1}
                        {ownPersonCount === "me" ? "1-3" : ownPersonCount === "fam" ? "4-6" : "7-12"}
                        {translations[language].tariffOwn.ownPersons2}
                        <br />
                        {ownPersonCount === "me" ? "500" : ownPersonCount === "fam" ? "1000" : "2000"}
                        {translations[language].tariffOwn.ownPersons3}
                    </div>
                ) : isNeverOwn ? (
                    <div className="tariffes-template__speed">
                        {translations[language].tariffOwn.ownSpeed1}
                        <br />
                        {translations[language].tariffOwn.ownSpeed2}
                    </div>
                ) : isOwnTariff ? (
                    <div className="tariffes-template__speed">
                        {translations[language].tariffOwn.ownWidget1}
                        {","}
                        {remoteData?.data?.endDate > today ? translations[language].tariffOwn.ownWidget5 + parseTimestamp(remoteData?.data?.endDate) : null}
                        <br />
                        {remoteData?.data?.endDate > today
                            ? remoteData?.data?.trafficTotal > 0
                                ? translations[language].tariffOwn.ownWidget7 +
                                  (remoteData?.data?.nextGB ? parseTimestamp(remoteData?.data?.nextGB) : parseTimestamp(remoteData?.data?.endDate)) +
                                  translations[language].tariffOwn.ownWidget8 +
                                  remoteData?.data?.trafficTotal +
                                  translations[language].textTips.gb
                                : translations[language].tariffOwn.ownWidget9 +
                                  getOwnTariffTypeGb() +
                                  translations[language].textTips.gb +
                                  " " +
                                  (remoteData?.data?.nextGB ? parseTimestamp(remoteData?.data?.nextGB) : parseTimestamp(remoteData?.data?.endDate))
                            : null}
                    </div>
                ) : isTestOwnTariff && remoteData?.data?.endDate >= today ? (
                    <div className="tariffes-template__speed">
                        {translations[language].tariffOwn.ownWidget1}
                        <br />
                        {translations[language].tariffOwn.ownWidget2}
                        {getTestedDays()}
                        {translations[language].tariffOwn.ownWidget10}
                    </div>
                ) : (
                    <div className="tariffes-template__speed">
                        {translations[language].tariffOwn.ownWidget1}
                        <br />
                        {translations[language].tariffOwn.ownWidget4}
                    </div>
                )
            ) : (
                <CurrentTariffWidget />
            )}

            <div
                className={`tariffes-template__content ${currentClass} ${currentClass === "own" ? `own${ownProgress}` : null} ${currentClass === "own" && ownProgress === 0 && "own-persons"} ${
                    currentClass === "own" && ownProgress === 2 && "own-country"
                }`}
            >
                {children}

                {(currentClass === "nolimit" || currentClass === "fit") && currentUser.isFirstRefUser && currentUser.refBalance <= 0 ? (
                    <span className={`first_buy_hint ${currentClass === "fit" ? "tariffes_full_price_fit" : "tariffes_full_price_nolimit"}`}>{translations[language].textTips.firstBuyHint}</span>
                ) : null}
            </div>

            {/* {(currentClass === 'own' && ownProgress === 1 && !currentUser.own_tested && !currentUser.owner) ? (
          <div className="tariff-own__test-toggle">
            <div className={isTestChecked === true ? `tariff-own__test-toggle__desc checked` : 'tariff-own__test-toggle__desc'}>
                <div className={`tariff-own__test-toggle__desc-title`}>
                    {translations[language].tariffOwn.ownTestTitle}
                </div>
                <div className={`tariff-own__test-toggle__desc-text`}>
                    {translations[language].tariffOwn.ownTestText} 
                </div>
            </div>
            <motion.div
                className={`tariff-own__test-toggle__motion ${isTestChecked ? 'own' : ''}`}
              
                onClick={handleTestCheck}>
                <motion.div
                className="tariff-own__test-toggle__motion-btn"
                variants={toggleVariants}
                animate={isTestChecked ? "test" : 'notest'}
                transition={{duration: 0.2}}
                />
            </motion.div>
         </div>)
      : null} */}
            <div className={`tariffes-template__content-secondary ${currentClass === "own" && ownProgress === 2 && "own-country"}`}>
                {currentClass === "fit" || currentClass === "nolimit" ? <SmartToggle handleMore={() => setIsMoreHidden(false)} currentClass={currentClass} onChange={handleChecked} /> : null}

                {currentUser.refBalance > 0 && (currentClass === "fit" || currentClass === "nolimit" || (currentClass === "own" && ownProgress == 2)) && (
                    <div className={`robo_balance_block ${currentClass == "fit" && "robo_balance_block_fit"} ${currentClass == "own" && "robo_balance_block_own"}`}>
                        <div className="robo_balance_amount_block">
                            <span className={`robo_balance_amount_block_label ${currentClass == "fit" && "tariffes_full_price_fit_label"} ${currentClass == "own" && "tariffes_full_price_own_label"}`}>
                                Ваш robo-баланс
                            </span>
                            <span className={`robo_balance_amount_block_value ${currentClass == "fit" && "tariffes_full_price_fit_label"} ${currentClass == "own" && "tariffes_full_price_own_value"}`}>
                                {`${currentUser.refBalance} (x2)`}
                            </span>
                            {/* FIXME: подставляем баланс и кол-во приглашенных */}
                        </div>
                        <div onClick={() => switchIsRoboBalance()} className="robo_balance_check_block">
                            <input
                                className={`robo_balance_check ${currentClass === "fit" && "check-fit"} ${currentClass === "nolimit" && "check-nolimit"} ${currentClass === "own" && "check-own"}`}
                                type="checkbox"
                                checked={withRoboBalance}
                                // {/* FIXME: нужно установить переменную `withRoboBalance` в state, чтобы передавать ее при оплате */}
                                // {/* FIXME: это сохраняет в state? В итоге нужно использовать в компоненте `Payment` */}
                                // onChange={switchIsRoboBalance}
                            />
                            <span className={`robo_balance_hint_value`}>Использовать robo-баланс для оплаты</span>
                        </div>
                    </div>
                )}

                {false && currentClass === "fit" ? (
                    <>
                        {/* <button
              className={`tariffes-template__content-button ${currentClass}`}
              onClick={() => setIsRecommendHidden(false)}
            >
              {translations[language].tariffes.tariffPopupTitleReccomend}
              <span
                className={`tariffes-template__content-button-arrow ${currentClass}`}
              />
            </button> */}
                        <button className={`tariffes-template__content-button ${currentClass}`} onClick={() => setIsGbHidden(false)}>
                            {translations[language].tariffes.tariffPopupTitleGbHow}
                            <span className={`tariffes-template__content-button-arrow ${currentClass}`} />
                        </button>
                    </>
                ) : null}

                {currentClass === "nolimit" || currentClass === "fit" ? (
                    <div className={`tariffes-template__amount ${currentClass === "fit" && "fit"} ${smart && (currentClass === "fit" ? "smart-fit" : "smart-nolimit")}`}>
                        <span className="tariffes-template__amount-title">{translations[language].tariffOwn.ownCount}</span>
                        <div className="tariffes-template__amout-prices">
                            <span>
                                {currentClass === "fit" ? fitResPrice : nolimitResPrice} {translations[language].textTips.currency}
                            </span>
                            {currentClass === "fit" ? (
                                <span className={`tariffes-template__amount-full ${fitFullPrice === 0 && "hidden"}`}>
                                    {fitFullPrice} {translations[language].textTips.currency}
                                </span>
                            ) : (
                                <span className={`tariffes-template__amount-full ${nolimitFullPrice === 0 && "hidden"}`}>
                                    {nolimitFullPrice} {translations[language].textTips.currency}
                                </span>
                            )}
                        </div>
                    </div>
                ) : null}

                <span className={`tariffes__error ${currentClass === "own" && "hidden"}`}>{error}</span>
                {currentClass === "bonus" ? <AppButton text={translations[language].appButton.addGb} handler={() => navigate("/tariffes/fit")} currentClass={`${currentClass} margin-bottom`} /> : null}
                <AppButton
                    text={`${buttonText} ${currentClass === "fit" ? fitResPrice : currentClass === "own" ? ownResPrice : nolimitResPrice} ${translations[language].textTips.currency}`}
                    handler={handler}
                    currentClass={`${smart && currentClass === "fit" && "app-button fit-smart"}
                         ${!smart && currentClass === "fit" && "app-button fit"}
                          ${smart && currentClass === "nolimit" && "app-button nolimit-smart"}
                          ${!smart && currentClass === "nolimit" && "app-button nolimit"} 
                          ${currentClass === "own" && "own-pay"}
                          ${paymentSmart && "own-smart"}
                          ${isPaymentHidden && "hidden"}`}
                />
                {/* <span className={`tariffes_full_price ${currentClass=='fit' && 'tariffes_full_price_fit'}`}>{translations[language].textTips.instead}{currentClass === 'fit' ? fitFullPrice : nolimitFullPrice} {translations[language].textTips.currency}</span> */}
                {/* <div className={`tariff-own_conditions-text ${(currentClass !== 'own' || (currentClass === 'own' && ownProgress !== '2')) && 'hidden'}`}>{translations[language].tariffOwn.ownGuaranteeAlways}</div> */}
                {/* <AppButton
              currentClass={`${(currentClass === 'fit' || currentClass === 'nolimit') && 'hidden'}
                              ${currentClass === 'own' && 'own'}`}
              text={translations[language].appButton.next}
              handler={handleProgress}
              /> */}
                {currentClass === "own" && ownProgress === 2 ? (
                    <span
                        className={`tariffes_full_price ${currentClass == "fit" && "tariffes_full_price_fit"} ${currentClass == "own" && "tariffes_full_price_own"} ${
                            currentClass == "own" && ownFullPrice <= 0 && "tariffes_full_price_own_hidden"
                        }`}
                    >
                        {translations[language].textTips.instead}
                        {currentClass === "fit" ? fitFullPrice : currentClass === "own" ? ownFullPrice : nolimitFullPrice} {translations[language].textTips.currency}
                    </span>
                ) : null}
                {/* {currentClass === 'own' ? (<span className={`tariffes-template__gurantee-${currentClass}`}>{translations[language].tariffOwn.ownGuarantee}</span>) : null} */}
            </div>
        </section>
    );
}
TariffesTemplate.propTypes = {
    currentClass: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    error: PropTypes.string,
    setIsFreeInfoHidden: PropTypes.func,
    setIsHidden: PropTypes.func.isRequired,
    setIsRecommendHidden: PropTypes.func,
    setIsGbHidden: PropTypes.func,
};
export default TariffesTemplate;
