import React from "react";
import "./CopyToClipboardField.css";
import copyIcon from "../../images/copy.svg";
import copiedIcon from "../../images/check.svg";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import useAnalyticsEventTracker from "../../hooks/useAnanlyticsEventTracker";
import { translations } from "../../utils/translations/translations";
import { useSelector } from "react-redux";
import platform from "platform-detect/os.mjs";
function CopyToClipboardField({ currentClass, data, gaAction }) {
    const [isCopied, setIsCopied] = React.useState(false);
    const copyToClipboardRef = React.useRef(null);
    const language = useSelector((state) => state.language);
    const gaEventTracker = useAnalyticsEventTracker("CopyLink");

    function copyToClipboard(e) {
        if (platform.android) {
            return;
        }
        e.stopPropagation();
        gaEventTracker(gaAction, "Key_copied");
        setIsCopied(true);
        navigator.clipboard.writeText(data);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    }

    return (
        <motion.button
            type="button"
            onClick={copyToClipboard}
            className={`copy-to-clipboard ${currentClass} ${isCopied && "high"} ${currentClass === "options__copy-to-clipboard" && "options__box-shadow"} ${platform.android && "android"}`}
            whileTap={!platform.android && { scale: 0.98 }}
        >
            {platform.android ? (
                <span ref={copyToClipboardRef} className={`copy-to-clipboard__key copy-to-clipboard__key_selectable ${currentClass}`}>
                    {data}
                </span>
            ) : (
                <span className={`copy-to-clipboard__key ${currentClass}`}>{data}</span>
            )}
            {!platform.android && <img className={`copy-to-clipboard__copy-icon ${false ? "hidden" : ""}`} src={copyIcon} alt="copy" />}
            {isCopied && (
                <div className={`copy-to-clipboard__popup ${currentClass}`}>
                    <span className={`copy-to-clipboard__popup-text ${currentClass}`}>{translations[language].copyToClipboard}</span>
                    <img className={`copy-to-clipboard__popup-icon ${currentClass}`} src={copiedIcon} alt="copied" />
                </div>
            )}
        </motion.button>
    );
}
CopyToClipboardField.propTypes = {
    currentClass: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    gaAction: PropTypes.string,
};
export default CopyToClipboardField;
