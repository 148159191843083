import React from "react";
import "./Help.css";
import BackButton from "../BackButton/BackButton";
import MenuButton from "../MenuButton/MenuButton";
import valuesIcon from "../../images/values.png";
import possibilitiesIcon from "../../images/possibilities.png";
import newsIcon from "../../images/news.png";
import loveSmileIcon from "../../images/love-smile-min.png";
import faqIcon from "../../images/faq.png";
import noResponceIcon from "../../images/noresponce.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { directionVariants } from "../../utils/directionOptions";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { parseTimestamp, getBonus, roundTraffic, getRefTraffic, getIsFree, getTariffEnded, getOnlyOwn } from "../../utils/helpers";
import { translations } from "../../utils/translations/translations";

function Help() {
    const today = new Date();
    const navigate = useNavigate();
    const direction = useSelector((state) => state.direction);
    const remoteData = useSelector((state) => state.remoteData);
    const language = useSelector((state) => state.language);
    const currentUser = useSelector((state) => state.currentUser);
    const nextFit = !isNaN(Number(currentUser.firstFitTariff)) && currentUser.firstFitTariff.trim() !== "" && typeof currentUser.firstFitTariff !== "string" ? currentUser.firstFitTariff : 0;

    const isFree = getIsFree(currentUser, getBonus);
    const tariffEnded = getTariffEnded(currentUser, getBonus, isFree);
    const onlyOwn = getOnlyOwn(currentUser, tariffEnded, remoteData?.data?.endDate);

    return (
        <motion.section
            className="help"
            initial={direction ? "fromLeft" : "fromRight"}
            animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
            exit={direction ? "exitToRight" : "exitToLeft"}
            variants={directionVariants}
        >
            <BurgerMenu color="var(--blue)" />
            <BackButton path="/" text={translations[language].appButton.mainMenu} currentClass="wide" title={translations[language].textTips.notUnderstand} />
            <div className="empty-block"></div>
            <div className="help__content">
                <MenuButton
                    handler={() => navigate("/values")}
                    image={valuesIcon}
                    currentClass="btn-our-values"
                    title={translations[language].textTips.ourValues}
                    text={translations[language].textTips.valuesText}
                    addText={null}
                />
                <MenuButton
                    handler={() => navigate("/possibilities")}
                    image={possibilitiesIcon}
                    currentClass="btn-our-possibilities"
                    title={translations[language].textTips.possibilities}
                    text={translations[language].textTips.possibilitiesText}
                />
                <div className="help__button-box">
                    {/* <MenuButton
            handler={() => navigate('/news')}
            image={newsIcon}
            currentClass='btn-news'
            title={translations[language].textTips.news}
            text={translations[language].textTips.newsText}
          /> */}
                    <MenuButton
                        handler={() => navigate("/referral")}
                        image={loveSmileIcon}
                        currentClass="btn-news"
                        title={translations[language].textTips.referral}
                        text={translations[language].textTips.referralBtnText}
                        addText={null}
                    />
                    <div className="help__button-news"></div>
                    <MenuButton handler={() => navigate("/faq")} image={faqIcon} currentClass="btn-faq" title={translations[language].textTips.faq} text={translations[language].textTips.faqText} />
                    <MenuButton
                        //handler={() => navigate('/message-us')}
                        handler={() => (window.location.href = "https://t.me/getrobovpnBot")}
                        image={noResponceIcon}
                        currentClass="btn-no-responce"
                        title={translations[language].textTips.noResponce}
                        text={translations[language].textTips.noResponceText}
                    />
                </div>
                <MenuButton
                    image={valuesIcon}
                    currentClass="btn-my-tariff"
                    title={translations[language].subscription.myTariff}
                    text={
                        translations[language].textTips.tariff +
                        (/^\d+$/.test(currentUser.tariff) ? "FIT" : currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toUpperCase()) +
                        (currentUser.smart === 1 ? translations[language].tariffes.smart : "")
                    }
                    addText={
                        isFree && (currentUser.regularTraffic > 0 || currentUser.smartTraffic > 0 || currentUser.referralTraffic > 0)
                            ? translations[language].textTips.left + getRefTraffic(currentUser.referralTraffic) + translations[language].textTips.gb
                            : !onlyOwn &&
                              ((/^\d+$/.test(currentUser.tariff) &&
                                  currentUser.smartTraffic <= 0 &&
                                  currentUser.referralTraffic <= 0 &&
                                  currentUser.regularTraffic <= 0 &&
                                  getBonus(currentUser.bonusTrafficNow) === 0) ||
                                  (/^\d+$/.test(currentUser.tariff) && currentUser.endDate < today && currentUser.referralTraffic < 0) ||
                                  (isFree && currentUser.regularTraffic <= 0 && currentUser.smartTraffic <= 0 && currentUser.referralTraffic <= 0) ||
                                  (currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() === "nolimit" && currentUser.endActiveDate < today && currentUser.referralTraffic <= 0))
                            ? translations[language].textTips.freeGb + parseTimestamp(currentUser.bonusDate)
                            : (currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() === "nolimit" && currentUser.endActiveDate > today) || onlyOwn
                            ? translations[language].textTips.activeUntil + (onlyOwn ? parseTimestamp(remoteData?.data?.endDate) : parseTimestamp(currentUser.endActiveDate))
                            : translations[language].textTips.left +
                              (currentUser.smartTraffic + currentUser.regularTraffic + parseInt(getRefTraffic(currentUser.referralTraffic)) + nextFit).toFixed(1) +
                              translations[language].textTips.gb +
                              (isFree ? "" : translations[language].textTips.till + parseTimestamp(currentUser.endDate))
                    }
                    handler={() => navigate("/subscription")}
                />
            </div>
        </motion.section>
    );
}

export default Help;
